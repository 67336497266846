import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../../redux/slice/auth.slice';
import text from '../../shared/css/text.module.scss';
import LoginForm from './LoginForm';
import LoginSlider from './LoginSlider';
import TwoFaForm from './TwoFaForm';
import styles from './login.module.scss';
import LogoIcon from '../../../static/svgs/logo';
import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
const LoginContainer = () => {
  const navigate = useNavigate();
  const auth = useSelector(authActions.selectors.getLoggedInStatus);
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth) {
      navigate('/');
    }
  }, [auth, navigate]);

  const handleBackClick = () => {
    dispatch(authActions.actions.setLoginTab(loginTab - 1))
  }

  const loginTab = useSelector(authActions.selectors.getLoginTab);
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <LogoIcon />
        {loginTab > 0 &&
          <div className={styles.backButtonContainer}>
            <IconButton onClick={handleBackClick}>
              <ArrowBack />
            </IconButton>
          </div>
        }
        <div className={styles.loginBox}>
          {loginTab === 0 &&
            <LoginForm />
          }

          {loginTab === 1 &&
            <TwoFaForm />
          }

        </div>
      </div>
      <div className={styles.right}>
        <div>
          <LoginSlider />
        </div>
        <p className={`${text.normal2} ${text.textWhite} ${text.textCenter}`}>© 2022 BDATA. All rights reserved</p>
      </div>
    </div>
  )
}

export default LoginContainer
