import toast from "react-hot-toast";
import { call, put, takeEvery } from "redux-saga/effects";
import { SOMETHING_WENT_WRONG } from "../../static/constants";
import { createStripePaymentServ, getSubscriptionPlansServ } from "../services/subscription.service";
import { subscriptionActions } from "../slice/subscription.slice";
import { CREATE_STRIPE_PAYMENT, GET_SUBSCRIPTION_PLANS } from "../types/subscription.types";
import { authActions } from "../slice/auth.slice";
import { SUBSCRIPTION_STATUS } from "components/utils/enums";

export function* getSubscriptionPlans() {
  try {
    const plans = yield getSubscriptionPlansServ();
    if (plans) {
      yield put(subscriptionActions.actions.setSubscriptionPlans(plans?.data));
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}

export function* createStripePayment(action) {
  try {

    const paymentResponse = yield call(() =>
      toast.promise(
        createStripePaymentServ(action.payload),
        {
          loading: "Processing payment... Please wait.",
          success: "Payment successful! Your subscription is now active. 🎉",
          error: (err) => {
            return err?.response?.data?.message || "Payment failed. Please try again or contact support.";
          }
        }
      )
    );

    if (paymentResponse) {
      yield put(subscriptionActions.actions.setSelectedPricingTab(2));
      yield put(authActions.actions.setSubscriptionStatus(SUBSCRIPTION_STATUS.active));
      console.log("payment successful")
    }
  } catch (err) {
    console.log("err", err);
  }
}


export function* watchSubscriptionAsync() {
  yield takeEvery(GET_SUBSCRIPTION_PLANS, getSubscriptionPlans);
  yield takeEvery(CREATE_STRIPE_PAYMENT, createStripePayment);
}
