export const TOPICS = {
    PORTS: "PORTS",
    SYSTEM: "SYSTEM",
    NETWORK_INTERFACES: "NETWORK_INTERFACES",
    PROCESSES: "PROCESSES",
    SYSTEM_PACKAGES: "SYSTEM_PACKAGES",
    APPLICATIONS: "APPLICATIONS",
    UNINSTALL_APP: "UNINSTALL_APP",
    EXECUTE_COMMAND: "EXECUTE_COMMAND",
    DEVICES: "DEVICES",
    ALL_APPS: "ALL_APPS",
    CONNECTION: "CONNECTION",
    EXECUTE_RESPONSE: "EXECUTE_RESPONSE",
    USERS: "USERS"
}


export const DeviceStatus = {
    registered: "Registered",
    connected: "Connected",
    disconnected: "Disconnected",
    activated: "Activated",
    add_credentials: "Add Credentials",
    verified: "Verified",
    verifying: "Verifying",
    partialConnected: "Partially Connected",
    reScanning: "Re-Scanning"
}

export const OS = {
    windows: "Windows",
    ubuntu: "Ubuntu"
}

export const SUBSCRIPTION_STATUS = {
    active: "active",
    canceled: "canceled",
    incomplete_expired: "incomplete_expired",
    incomplete: "incomplete",
    trialing: "trialing",
    past_due: "past_due",
    unpaid: "unpaid",
    paused: "paused",
    expired: "expired",
    failed: "failed",
    pending: "pending"
};
