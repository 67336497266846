import { Button, Card } from "@mui/material";
import text from "components/shared/css/text.module.scss";
import CustomTextField from "components/shared/Forms/CustomTextField";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import EmailIcon from "static/svgs/Email";
import LockIcon from "static/svgs/Lock";
import ProfileIcon from "static/svgs/Profile";
import { object } from "yup";
import { SIGN_UP } from "../../../redux/types/auth.types";
import { YupSignUpSchema, decryptValue, formSignUpSchema } from "./helper";
import styles from "./signup.module.scss";

const SignUpForm = () => {
  let signUpSchema = object(YupSignUpSchema);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm(formSignUpSchema(signUpSchema));
  const location = useLocation();

  const EMAIL_SECRET = process.env.REACT_APP_EMAIL_SECRET;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const encryptedEmail = params.get("email");
    const encryptedOrgId = params.get("orgId");
    const encryptedRole = params.get("role");

    if (encryptedEmail && encryptedOrgId && encryptedRole) {
      const decryptedEmail = decryptValue(encryptedEmail, EMAIL_SECRET);
      const decryptedOrgId = decryptValue(encryptedOrgId, EMAIL_SECRET);
      const decryptedRole = decryptValue(encryptedRole, EMAIL_SECRET);
      console.log("decryptedRole", decryptedRole)
      console.log("decryptedEmail", decryptedEmail)
      setValue("email", decryptedEmail)
      setValue("orgId", decryptedOrgId)
      setValue("role", decryptedRole)
    }
  }, [EMAIL_SECRET, location.search, setValue]);

  const handleSignupSubmit = async (payload) => {
    dispatch({ type: SIGN_UP, payload })
  };

  return (
    <Card className={styles.formContainer}>
      <div>
        <p className={text.heading1}>Sign Up</p>
        <p className={text.info1}>
          Enter your credentials to signup to the portal
        </p>
        <br />
      </div>
      <form
        onSubmit={handleSubmit(handleSignupSubmit)}
        className={styles.fieldsContainer}
      >
        <CustomTextField
          control={control}
          errors={errors}
          name={"email"}
          type="text"
          label="Email"
          icon={EmailIcon}
          placeHolder={"Enter your email"}
          // email={email}
          disabled={true}
        />
        <CustomTextField
          control={control}
          errors={errors}
          name={"username"}
          type="text"
          label="Username"
          icon={ProfileIcon}
          required={true}
          placeHolder={"Enter your username"}
        />
        <CustomTextField
          control={control}
          errors={errors}
          name={"password"}
          type="password"
          label="Password"
          icon={LockIcon}
          required={true}
          placeHolder={"*******"}
        />
        <br />
        <div className={styles.btnContainer}>
          <Button
            disabled={!isValid}
            type="submit"
            variant="contained"
            className={styles.loginBtn}
          >
            Sign Up
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default SignUpForm;
