import { SUBSCRIPTION_STATUS } from 'components/utils/enums';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { authActions } from '../../redux/slice/auth.slice';

const PrivateSubscriptionRoutes = () => {
    const auth = useSelector(authActions.selectors.getLoggedInStatus);
    const subscriptionStatus = useSelector(authActions.selectors.getSubscriptionStatus)


    return (
        auth ?
            subscriptionStatus !== SUBSCRIPTION_STATUS.active ?
                <Outlet />
                :
                <Navigate to='/' />

            : <Navigate to='/login' />
    )
}

export default PrivateSubscriptionRoutes;