import { createSlice, createAction } from "@reduxjs/toolkit";

// Define a global reset action
const resetAll = createAction("RESET_STATE");

// Create a slice for reset (even though it has no state)
const resetSlice = createSlice({
    name: "reset",
    initialState: {},
    reducers: {},
});

const actions = {
    resetAll, // Exporting the reset action in a structured format
};

export const resetActions = { actions };

export default resetSlice;
