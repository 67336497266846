import { Grid } from '@mui/material';
import KeyFeatures from 'components/modules/Networks/NetworkKeyFeatures/KeyFeatures/KeyFeatures';
import MapSummaryTable from 'components/modules/Networks/NetworkKeyFeatures/MapSummary/MapSummaryTable';
import CustomTitle from 'components/shared/CustomTitle';
import CustomDialog from 'components/shared/Dialog/CustomDialog';
import theme from 'components/utils/theme';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendToSocket, setUpSingleNetworksocket, unSubscribeSocketTopics } from '../../../redux/saga/socket';
import { networkDeviceActions } from '../../../redux/slice/networkDevice.slice';
import { uiActions } from '../../../redux/slice/ui.slice';
import { GET_SINGLE_NETWORK_LOCATION, GET_SINGLE_NETWORKS_DEVICES } from '../../../redux/types/networkDevice.types';
import DeviceMap from '../Device/DeviceMap/DeviceMap';
import { getMarker } from '../Device/helper';
import MultiTopology from '../Networks/NetworkKeyFeatures/TopologyDetails/MultiTopology';

const SingleNetwork = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const selectedNetwork = useSelector(networkDeviceActions.selectors.getSelectedNetwork);

    useEffect(() => {
        dispatch(uiActions.actions.setSelectedNav(""));

        if (id) {
            const payload = {
                networkId: id
            }
            dispatch({ type: GET_SINGLE_NETWORKS_DEVICES, payload })
            dispatch({ type: GET_SINGLE_NETWORK_LOCATION, payload })

            sendToSocket("GET_SINGLE_NETWORK", payload)
        }
    }, [dispatch, id]);

    const currentDeviceLoc = useSelector(networkDeviceActions.selectors.getCurrentDeviceLocation)

    useEffect(() => {
        setUpSingleNetworksocket(dispatch);

        return () => {
            unSubscribeSocketTopics(["SINGLE_NETWORK_RESP"]);
        };
    }, [dispatch])

    const [isFullScreen, setIsFullScreen] = useState(false);
    const handleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };


    const markers = useMemo(() => {
        if (currentDeviceLoc?.loc) {
            const coordinates = currentDeviceLoc?.loc?.split(",").map(Number).reverse();
            if (coordinates?.length === 2) {
                const html = getMarker(theme.palette.success.main);
                return [{ coordinates, html, device: currentDeviceLoc?.device }];
            }
        }
        return [];
    }, [currentDeviceLoc]);

    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <CustomTitle title={"Network Details"} isCount={false} />
            </Grid>
            <Grid item xs={12}>
                <KeyFeatures connections={selectedNetwork?.connections} />
            </Grid>
            <Grid item xs={12} md={8} sx={{ position: 'relative' }}>
                <MultiTopology xOffset={0} yOffset={70} isFullScreen={isFullScreen} deviceNetworks={[selectedNetwork]} handleFullScreen={handleFullScreen} />
            </Grid>
            <Grid item height={'100%'} xs={12} md={4}>
                <DeviceMap showNetworkId={true} markers={markers} center={currentDeviceLoc?.loc} height="418px" />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
                <CustomTitle title={"Devices List"} isCount={false} />
                <MapSummaryTable deviceNetworks={[selectedNetwork]} isSingle={true} />
            </Grid>
            <CustomDialog fullScreen={true} title={'List Topology Details'} isOpen={isFullScreen} handleToggle={handleFullScreen} maxWidth={'xl'}>
                <Grid xs={12} height={'100%'}>
                    <MultiTopology xOffset={10} yOffset={115} isFullScreen={isFullScreen} deviceNetworks={[selectedNetwork]} handleFullScreen={handleFullScreen} />
                </Grid>
            </CustomDialog>
        </Grid>
    )
}

export default SingleNetwork
