import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedNav: 'Dashboard',
    selectedMultiTab: 0
}

const name = 'ui'
const parentSelector = state => state?.[name]

const uiSlice = createSlice({
    name: name,
    initialState: initialState,
    reducers: {
        resetUI: () => {
            return initialState;
        },
        setSelectedNav: (state, action) => {
            return {
                ...state,
                selectedNav: action.payload,
            };
        },
        setSelectedMultiTab: (state, action) => {
            return {
                ...state,
                selectedMultiTab: action.payload,
            };
        },

    },
});


const getSelectedNav = createSelector(parentSelector, state => {
    return state?.selectedNav
})

const getSelectedMultiTab = createSelector(parentSelector, state => {
    return state?.selectedMultiTab
})




const actions = {
    ...uiSlice.actions
}

const selectors = {
    getSelectedNav,
    getSelectedMultiTab,
}

export const uiActions = { actions, selectors }


export default uiSlice;