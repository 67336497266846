import dayjs from "dayjs";
import { executeCommand } from "../../../redux/saga/socket";
import PeopleIcon from "../../../static/svgs/People";
import RegisterSettingsIcon from "../../../static/svgs/RegisterSettings";
import UserAppsIcon from '../../../static/svgs/UserApps';
import UsersIcon from "../../../static/svgs/users";
import MyBadge from "../../shared/MyBadge";
import theme from "../../utils/theme";
import { commandTypes, formatFileSize } from "components/utils";
import { string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const windowsAppColumns = [
    {
        field: 'name',
        headerName: 'App Name',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'signature',
        headerName: 'Signature',
        minWidth: 70,
        flex: 2
    },
    {
        field: 'location', headerName: 'InstallLocation', minWidth: 120, flex: 2
    }
];

export const linuxAppColumns = [
    {
        field: 'name',
        headerName: 'File Name',
        minWidth: 120,
        flex: 1.2
    },

    // {
    //     field: 'InstallDate',
    //     headerName: 'Install Date',
    //     minWidth: 100,
    //     flex: 0.5,
    //     renderCell: (params) => dayjs(params?.row?.InstallDate).format('DD-MM-YYYY'),
    // },
    {
        field: 'signature',
        headerName: 'Signature',
        minWidth: 70,
        flex: 3
    },
    // {
    //     field: 'InstallLocation', headerName: 'InstallLocation', minWidth: 120, flex: 1
    // },
    // {
    //     field: 'Publisher', headerName: 'Publisher', minWidth: 120, flex: 1
    // },
];

export const userAppsColumns = [
    {
        field: 'Name',
        headerName: 'File Name',
        minWidth: 120,
        flex: 1.2
    },

    {
        field: 'InstallDate',
        headerName: 'Install Date',
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => dayjs(params?.row?.InstallDate).format('DD-MM-YYYY'),
    },
    {
        field: 'Version',
        headerName: 'Version',
        minWidth: 70,
        flex: 0.7
    },
    {
        field: 'InstallLocation', headerName: 'InstallLocation', minWidth: 120, flex: 1
    },
    {
        field: 'Publisher', headerName: 'Publisher', minWidth: 120, flex: 1
    },
];


export const getStatusBadge = (name) => {
    // eslint-disable-next-line default-case
    switch (name) {
        case 'Installed':
            return <MyBadge title={name} bgColor={theme.palette.success.light} txtColor={theme.palette.success.main} />
        case 'Pending':
            return <MyBadge title={name} bgColor={theme.palette.brown.light} txtColor={theme.palette.brown.main} />
    }
}

export const getActiveBadge = (name) => {
    // eslint-disable-next-line default-case
    switch (name) {
        case 'Known':
            return <MyBadge title={name} bgColor={theme.palette.white.dark} txtColor={theme.palette.success.main} />
        case 'UnKnown':
            return <MyBadge title={name} bgColor={theme.palette.white.dark} txtColor={theme.palette.brown.main} />
    }
}

export const getRoleBadge = (name) => {
    // eslint-disable-next-line default-case
    switch (name) {
        case 'General':
            return <MyBadge title={name} bgColor={theme.palette.success.light} txtColor={theme.palette.text.primary} />
        case 'Admin':
            return <MyBadge title={name} bgColor={theme.palette.brown.light} txtColor={theme.palette.brown.main} />
        case 'Creator':
        case 'User':
            return <MyBadge title={name} bgColor={theme.palette.white.dark} txtColor={theme.palette.text.primary} />
    }
}

export const getActiveStatusBadge = (name) => {
    // eslint-disable-next-line default-case
    switch (name) {
        case 'Active':
            return <MyBadge title={name} bgColor={theme.palette.success.light} txtColor={theme.palette.success.main} />
        case 'In Active':
            return <MyBadge title={name} bgColor={theme.palette.brown.light} txtColor={theme.palette.brown.main} />
    }
}

export const getAppInstalledItems = (total, active, registered) => {
    return (
        [
            { value: total, label: 'User Apps', icon: <PeopleIcon />, color: theme.palette.primary.light },
            { value: active, label: 'Active Apps', icon: <UsersIcon />, color: theme.palette.secondary.main },
            { value: registered, label: 'Register Apps', icon: <RegisterSettingsIcon />, color: theme.palette.primary.main },
        ]
    )
}

export const getUserAppItems = (registered, active, users) => {
    return (
        [
            { value: registered, label: 'System Apps', icon: <UsersIcon />, color: theme.palette.secondary.main },
            { value: active, label: 'Active Apps', icon: <RegisterSettingsIcon />, color: theme.palette.primary.main },
            { value: users, label: 'Register Apps', icon: <UserAppsIcon />, color: theme.palette.primary.light },
        ]
    )
}

export const handleDeleteApps = ({ selectedApps, filteredData, dispatchAction, dispatch, selectedDevice, keyName = "PackageFullName", command = commandTypes.UN_INST_APP }) => {
    const filteredObjects = filteredData.filter((object) =>
        selectedApps.includes(object.id)
    );
    console.log("filteredObjects", filteredObjects)
    const names = filteredObjects?.map((item) => item?.[keyName]);
    executeCommand(names, command, selectedDevice?.uid, selectedDevice?.systemDetails?.os);
    const nonFilteredObjects = filteredData.filter(
        (object) => !selectedApps.includes(object.id)
    );
    console.log("nonFilteredObjects", nonFilteredObjects);
    dispatch(dispatchAction(nonFilteredObjects));
};


export const YupMalwareSearchSchema = {
    hash: string().required('Hash is required')
}

export const formMalwareSearchSchema = (twoFaSchema) => {
    return {
        resolver: yupResolver(twoFaSchema),
        mode: 'onChange',
        defaultValues: {
            otp: '',
        }
    }
}