import axios from "axios";

const authInterceptor = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
});

// Add a request interceptor for public requests
authInterceptor.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a request interceptor for authenticated requests
authInterceptor.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


export default authInterceptor;
