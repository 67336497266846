import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { authActions } from '../../redux/slice/auth.slice';
import { VALIDATE_ACCESS_TOKEN } from '../../redux/types/auth.types';
import Layout from './Layout';
import { SUBSCRIPTION_STATUS } from 'components/utils/enums';

const PrivateLayoutRoutes = () => {
  const dispatch = useDispatch();
  const auth = useSelector(authActions.selectors.getLoggedInStatus);
  const subscriptionStatus = useSelector(authActions.selectors.getSubscriptionStatus)

  useEffect(() => {
    dispatch({ type: VALIDATE_ACCESS_TOKEN })
  }, [dispatch])

  return (
    auth ?
      subscriptionStatus === SUBSCRIPTION_STATUS.active ? <>
        <Layout>
          <Outlet />
        </Layout>
      </>
        :
        <Navigate to='/pricing' />

      : <Navigate to='/login' />
  )
}

export default PrivateLayoutRoutes;