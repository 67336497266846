import { Button, Card } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { object } from 'yup';
import { USER_LOGIN } from '../../../redux/types/auth.types';
import EmailIcon from 'static/svgs/Email';
import LockIcon from 'static/svgs/Lock';
import CustomTextField from 'components/shared/Forms/CustomTextField';
import text from 'components/shared/css/text.module.scss'
import { YupLoginSchema, formLoginSchema } from './helper';
import styles from './login.module.scss';

const LoginForm = () => {
  const dispatch = useDispatch();
  let loginSchema = object(YupLoginSchema);
  const { control, handleSubmit, formState: { errors, isValid } } = useForm(formLoginSchema(loginSchema))

  // navigate('/');
  const handleLoginSubmit = (payload) => {
    payload.email = payload.email.toLowerCase()
    dispatch({ type: USER_LOGIN, payload })
  };

  return (
    <Card className={styles.formContainer}>
      <div>
        <p className={text.heading1}>Login</p>
        <p className={text.info1}>Enter your credentials to login to the portal</p>
        <br />
      </div>
      <form onSubmit={handleSubmit(handleLoginSubmit)} className={styles.fieldsContainer}>
        <CustomTextField
          control={control}
          errors={errors}
          name={'email'}
          type="text"
          label="Email"
          icon={EmailIcon}
          required={true}
          placeHolder={'Enter your email'}
        />
        <CustomTextField
          control={control}
          errors={errors}
          name={'password'}
          type="password"
          label="Password"
          icon={LockIcon}
          required={true}
          placeHolder={'*******'}
        />

        <br />
        <div className={styles.btnContainer}>
          <Button disabled={!isValid} type="submit" variant='contained' className={styles.loginBtn} >Login</Button>
          <Link to='/forgot-password' className={styles.forgotBtn}>Forgot Password?</Link>
        </div>
      </form>
    </Card >
  )
}

export default LoginForm
