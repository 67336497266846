import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CustomButton from "components/shared/Buttons/CustomButton";
import theme from "components/utils/theme";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { CREATE_STRIPE_PAYMENT } from "../../../redux/types/subscription.types";
import forms from '../../shared/Forms/forms.module.scss';
import text from '../../shared/css/text.module.scss';
import styles from "./pricingPlan.module.scss";
import { BILLING_CYCLES } from "./helper";

const PaymentForm = ({ selectedPlan, isYearly }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    console.log("selectedPlan", selectedPlan)

    const handlePayment = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            toast.error("Something went wrong. Please try again")
            return;
        }

        setLoading(true);
        const cardNumberElement = elements.getElement(CardNumberElement);

        try {
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: "card",
                card: cardNumberElement,
            });

            if (error) {
                toast.error("Something went wrong in Payment! Please try again");
                return;
            }

            const payload = {
                planId: selectedPlan?._id,
                priceId: isYearly ? selectedPlan?.yearlyPriceId : selectedPlan?.monthlyPriceId,
                paymentMethodId: paymentMethod.id,
                billingCycle: isYearly ? BILLING_CYCLES.yearly : BILLING_CYCLES.monthly
            };
            // const paymentResponse = await createStripePaymentServ(payload);
            dispatch({ type: CREATE_STRIPE_PAYMENT, payload })


        } catch (error) {
            console.error("Error during payment:", error);
            toast.error("Payment failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className={styles.paymentFormContainer}>
            <Box className={styles.container}>
                <Typography variant="body1" fontWeight={'bold'}>Enter Billing Details</Typography>
                <br />
                <form onSubmit={handlePayment} className={styles.form}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item xs={12} md={12} className={styles.paymentInput}>
                            <label className={`${text.formLabel} ${forms.label}`}>
                                Card Number
                            </label>
                            <div >
                                <CardNumberElement options={elementOptions} />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5.8} className={styles.paymentInput}>
                            <label className={`${text.formLabel} ${forms.label}`}>
                                Expiry Date
                            </label>
                            <CardExpiryElement options={elementOptions} />
                        </Grid>
                        <Grid item xs={12} md={5.8} className={styles.paymentInput}>
                            <label className={`${text.formLabel} ${forms.label}`}>
                                CVC
                            </label>
                            <CardCvcElement options={elementOptions} />
                        </Grid>
                        <Grid container item xs={12} md={12} justifyContent={'flex-end'}>
                            <CustomButton
                                padding={"0px 50px"}
                                color={theme.palette.background.default}
                                bgColor={theme.palette.primary.main}
                                hover={theme.palette.info.main}
                                label={loading ? <CircularProgress size={24} color={"inherit"} /> : "Pay"}
                                disabled={!stripe || loading}
                                type={"submit"}
                            />
                        </Grid>
                    </Grid>
                </form>
            </Box >
        </div >
    );
};

// Custom styles for Stripe's CardElement
const elementOptions = {
    style: {
        base: {
            fontSize: "16px",
            color: theme.palette.text.primary,
            "::placeholder": { color: theme.palette.gray.main },
        },
        invalid: {
            color: theme.palette.error.main,
        },
    },
};

export default PaymentForm;
