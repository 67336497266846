import { Card, CardContent, Skeleton, Stack } from '@mui/material';
import styles from './pricingPlan.module.scss';

const PricingSkeleton = () => {
    return (
        <Card className={styles.priceCard}>
            <CardContent>
                <Skeleton variant="text" width="40%" height={30} />
                <Skeleton variant="text" width="30%" height={40} />
                <Skeleton variant="text" width="80%" height={20} />
                <Skeleton variant="text" width="70%" height={20} />
                <Skeleton variant="text" width="50%" height={25} />
                <Stack spacing={1}>
                    {[...Array(3)].map((_, index) => (
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            key={index}
                        >
                            <Skeleton
                                variant="circular"
                                width={20}
                                height={20}
                            />
                            <Skeleton variant="text" width="60%" height={20} />
                        </Stack>
                    ))}
                </Stack>
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={35}
                    style={{ marginTop: '1rem' }}
                />
            </CardContent>
        </Card>
    );
};

export default PricingSkeleton;
