import { Warning } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton } from "@mui/material";
import styles from "./CustomToast.module.scss"; // Import SCSS module
import { useNavigate } from "react-router-dom";

const CustomToast = ({ open, onClose, severity = "warning", message, linkText, linkUrl }) => {
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(linkUrl)
    }

    if (!open) return null;
    return (
        <div className={styles.banner}>
            <Alert
                severity={severity}
                variant="filled"

                icon={<Warning />}
                action={
                    <IconButton size="small" onClick={onClose} className={styles.closeButton}>
                        <CloseIcon style={{ color: "#000" }} />
                    </IconButton>
                }
                sx={{ display: 'flex' }}
                className={`${styles.alert} ${styles[severity]}`} // Dynamically apply styles
            >

                {message}{" "}

                {linkText && linkUrl && (
                    <span onClick={handleNavigate} className={styles.link}>{linkText}</span>
                )}
            </Alert>
        </div>
    );
};

export default CustomToast;
