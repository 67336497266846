import { Button, Card } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { object } from "yup";
import { authActions } from "../../../redux/slice/auth.slice";
import { USER_VERIFY } from "../../../redux/types/auth.types";
import TwoFaIcon from "static/svgs/TwoFa";
import CustomTextField from "components/shared/Forms/CustomTextField";
import text from "components/shared/css/text.module.scss";
import { YupTwoFaSchema, formTwoFaSchema } from "./helper";
import styles from "./forgotpass.module.scss";

const TwoFaForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(authActions.selectors.getLoggedInStatus);
  const user = useSelector(authActions.selectors.getCurrentUser);
  useEffect(() => {
    if (auth) {
      navigate("/reset-password");
    }
  }, [auth, navigate]);

  let twoFaSchema = object(YupTwoFaSchema);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm(formTwoFaSchema(twoFaSchema));

  const handleLoginSubmit = (payload) => {
    payload.email = user?.email.toLowerCase();
    dispatch({ type: USER_VERIFY, payload });
  };

  return (
    <Card className={styles.formContainer}>
      <div>
        <p className={text.heading1}>Two Factor Authentication</p>
        <p className={text.info1}>Enter your code to get login</p>
        <br />
      </div>
      <form
        onSubmit={handleSubmit(handleLoginSubmit)}
        className={styles.fieldsContainer}
      >
        <CustomTextField
          control={control}
          errors={errors}
          name={"otp"}
          type="password"
          label="Code"
          icon={TwoFaIcon}
          required={true}
          placeHolder={"****"}
        />

        <br />
        <div className={styles.btnContainer}>
          <Button
            disabled={!isValid}
            type="submit"
            variant="contained"
            className={styles.loginBtn}
          >
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default TwoFaForm;
