import axios from 'axios';
import { authActions } from '../../slice/auth.slice';
import { store } from '../../store';

const apiInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor for public requests
apiInterceptor.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a request interceptor for authenticated requests
apiInterceptor.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

apiInterceptor.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log("error", error)
        if (error?.response && error?.response?.status === 401) {
            // store.dispatch(authActions.actions.resetAuth());
        }
        // Do something with response error
        return Promise.reject(error);
    }
);

export default apiInterceptor;
