import { VisibilityOutlined } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import VisibleNoneIcon from '../../../static/svgs/VisibleNone';
import forms from './forms.module.scss';
import text from '../css/text.module.scss';
import { inputTextFieldStyles } from './helper';
import { FormControl, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomTextField = ({ control, errors, name, type, label, icon: IconComponent, required, placeHolder, disabled = false, borderRadius = "50px", size = 'medium' }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className={forms.customTextField}>
            {label && (
                <label className={`${text.formLabel} ${forms.label}`}>
                    {label}{required && <span className={forms.estaric}>*</span>}
                </label>
            )}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControl
                        error={!!(errors && errors[name] && errors[name].message)}
                        fullWidth>
                        <TextField
                            type={type === 'password' && !showPassword ? 'password' : 'text'}
                            variant="outlined"
                            {...field}
                            size={size}
                            placeholder={placeHolder}
                            InputProps={{
                                startAdornment: IconComponent && (
                                    <InputAdornment position="start">
                                        <IconComponent />
                                    </InputAdornment>
                                ),
                                endAdornment: type === 'password' && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleTogglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOutlined /> : <VisibleNoneIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                style: {
                                    ...inputTextFieldStyles, borderRadius: borderRadius,
                                }
                            }}
                            fullWidth
                            disabled={disabled}
                        />
                        <FormHelperText>
                            {errors && errors[name] && errors[name].message}
                        </FormHelperText>
                    </FormControl>
                )}
            />
        </div>
    );
};

export default CustomTextField;
