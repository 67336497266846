import styled from "@emotion/styled";
import { Switch } from "@mui/material";
import theme from "components/utils/theme";
import { yupResolver } from "@hookform/resolvers/yup";
import { string } from "yup";


export const AntSwitch = styled(Switch)(() => ({
    width: 30,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(14px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
        },
        '&.Mui-checked': {
            transform: 'translateX(14px)',
            color: '#fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));



export const YupBillingSchema = {
    email: string().email().required(),
    password: string().required()
}

export const formBillingSchema = (billingSchema) => {
    return {
        resolver: yupResolver(billingSchema),
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
        }
    }
}

export const BILLING_CYCLES = {
    monthly: "monthly",
    yearly: "yearly"
}

