import { ArrowBack } from '@mui/icons-material';
import { Card, Grid, IconButton, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CustomButton from 'components/shared/Buttons/CustomButton';
import theme from 'components/utils/theme';
import { useDispatch } from 'react-redux';
import { subscriptionActions } from '../../../redux/slice/subscription.slice';
import text from '../../shared/css/text.module.scss';
import PaymentForm from './PaymentForm';
import styles from './pricingPlan.module.scss';
const BillingBody = ({ selectedPlan, isYearly, selectedTab }) => {
    const dispatch = useDispatch()


    const stripePromise = loadStripe('pk_test_51Qlsv1Ck8reJRKfKiTlmMP5NH8IhMquEmPIUaqWC6D5MgKfoWPDEF9LpOFqNGs3D8zmPkSOhJprHA4HRwoXo1rUA00iv0Se9eR');

    const handlePreviousTab = () => {
        if (selectedTab > 0) {
            dispatch(subscriptionActions.actions.setSelectedPricingTab(selectedTab - 1))
        }
    }

    return (
        <Grid container justifyContent={'center'}>
            <Grid xs={10} >
                {selectedTab > 0 &&
                    <div className={styles.navigationButton}>
                        <IconButton onClick={handlePreviousTab}>
                            <ArrowBack />
                        </IconButton>
                    </div>
                }
                <Card className={styles.subscriptionPlanCard}>
                    <Typography variant="body1" fontWeight={'bold'}>Subscription Plan</Typography>
                    <div className={styles.innerCard}>
                        <div className={styles.planDetail}>
                            <Typography variant="body1" fontWeight={'bold'}>{selectedPlan?.name} Subscription Package</Typography>
                            <Typography fontWeight={'bold'} className={text.info1}><b>${isYearly ? selectedPlan?.yearlyPrice : selectedPlan?.monthlyPrice}/</b>Per {isYearly ? "Year" : "Month"}</Typography>
                        </div>
                        <CustomButton
                            padding={"0px 50px"}
                            color={theme.palette.background.default}
                            bgColor={theme.palette.primary.main}
                            hover={theme.palette.info.main}
                            label={"Upgrade"}
                            onClick={handlePreviousTab}
                            type={"text"}
                        />
                    </div>
                </Card>
                <br />
                <Card className={styles.subscriptionPlanCard}>
                    <Elements stripe={stripePromise}>
                        <PaymentForm selectedPlan={selectedPlan} isYearly={isYearly} />
                    </Elements>
                </Card>
            </Grid>

        </Grid>
    )
}

export default BillingBody
