import ProfileMenu from 'components/shared/navbar/ProfileMenu/ProfileMenu';
import { useDispatch, useSelector } from 'react-redux';
import LogoIcon from 'static/svgs/logo';
import { authActions } from '../../../redux/slice/auth.slice';
import { subscriptionActions } from '../../../redux/slice/subscription.slice';
import BillingBody from './BillingBody';
import PricingBody from './PricingBody';
import styles from './pricingPlan.module.scss';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTION_STATUS } from 'components/utils/enums';

const PricingPlan = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUser = useSelector(authActions.selectors.getCurrentUser);
    const selectedTab = useSelector(subscriptionActions.selectors.getSelectedPricingTab);
    const selectedPlan = useSelector(subscriptionActions.selectors.getSelectedPlan)
    const isYearly = useSelector(subscriptionActions.selectors.getIsYearlyBilling);
    const subscriptionStatus = useSelector(authActions.selectors.getSubscriptionStatus)
    const handleChangeIsYearly = (isYearlyBilling) => {
        dispatch(subscriptionActions.actions.setIsYearlyBilling(isYearlyBilling))
    }


    const handleSubmitPlan = (plan) => {
        dispatch(subscriptionActions.actions.setSelectedPricingTab(selectedTab + 1))
        dispatch(subscriptionActions.actions.setSelectedPlan(plan))
    }
    useEffect(() => {
        if (selectedTab === 2 && subscriptionStatus === SUBSCRIPTION_STATUS.active) {
            navigate("/")
        }
    }, [navigate, selectedTab, subscriptionStatus])

    const renderPricingTab = (tab) => {
        switch (tab) {
            case 0:
                return <PricingBody handleChangeIsYearly={handleChangeIsYearly} isYearly={isYearly} handleSubmitPlan={handleSubmitPlan} />
            case 1:
                return <BillingBody selectedPlan={selectedPlan} isYearly={isYearly} selectedTab={selectedTab} />
            default:
                return <PricingBody handleChangeIsYearly={handleChangeIsYearly} isYearly={isYearly} handleSubmitPlan={handleSubmitPlan} />
        }
    }

    return (
        <div className={styles.pricingContainer}>
            <div className={styles.pricingNavContainer}>
                <LogoIcon />
                <ProfileMenu user={currentUser} showDropDown={true} />
            </div>

            <div className={styles.pricingBodyContainer}>
                {renderPricingTab(selectedTab)}
            </div>
        </div>
    )
}

export default PricingPlan
