import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { call, put, takeEvery } from "redux-saga/effects";
import { GENERAL_ERROR } from "../../static/static";
import {
  changePasswordService,
  deleteUserService,
  forgotPasswordServ,
  loginServ,
  resetPasswordService,
  send2FAotpService,
  update2FAService,
  updateUserService,
  verify2FAotpService,
  verifyUserServ,
} from "../services/auth.service";
import { authActions } from "../slice/auth.slice";
import {
  CHANGE_PASSWORD,
  DELETE_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SEND_TWO_FA_OTP,
  SIGN_UP,
  TWO_FA,
  USER_LOGIN,
  USER_LOGOUT,
  USER_VERIFY,
  VALIDATE_ACCESS_TOKEN,
  VERIFY_TWO_FA_OTP,
} from "../types/auth.types";
import { uploadProfileImageService } from "../services/auth.service";
import { UPLOAD_PROFILE_IMAGE, UPDATE_USER } from "../types/auth.types";
import { createUserService, validateTokenService } from "../services/group.service";
import { SUBSCRIPTION_STATUS } from "components/utils/enums";
import { resetActions } from "../slice/reset.slice";

export function* loginSaga(action) {
  try {

    const user = yield call(() =>
      toast.promise(
        loginServ(action.payload),
        {
          loading: 'Signing in, please wait...',
          success: (user) => {
            console.log("user", user);
            return `A 4-digit OTP has been sent to ${user?.data?.email || "your email"}`
          },
          error: "Sign-in failed. Please check your credentials and try again.",
        }
      )
    );

    if (user) {
      console.log("user?.data", user?.data);
      yield put(authActions.actions.setCurrentUserSlice(user?.data));
      yield put(authActions.actions.setLoginTab(1));
    }

  } catch (err) {
    console.log("err", err?.message);
  }
}

export function* verifyUserSaga(action) {
  try {
    const user = yield verifyUserServ(action.payload);
    console.log("user", user);
    if (user) {
      yield put(authActions.actions.loginUserStatus(true));
      yield put(authActions.actions.setCurrentUserSlice(user?.data));
      localStorage?.setItem("accessToken", user?.data?.accessToken);
      Cookies.set("refreshToken", user?.data?.refreshToken, {
        expires: 7,
        secure: true,
      });

      console.log("object")
      if (action.payload?.otp) {
        toast.success("OTP Verified Successfully")
      } else {
        toast.success("Logged In Successfully");
      }
    } else {
      toast.error("Failed to Authenticate");
    }
  } catch (err) {
    // console.log("err", err?.message);
    toast.error(err?.response?.data?.message || GENERAL_ERROR);
  }
}

export function* logoutSaga() {
  try {
    localStorage.clear();

    yield put(resetActions.actions.resetAll());
  } catch (err) {
    console.log("err", err);
    toast.error(err?.response?.data?.message || GENERAL_ERROR);
  }
}

function* uploadProfileImageSaga(action) {
  try {
    yield put(authActions.actions.uploadProfileImageRequest());
    const response = yield call(uploadProfileImageService, action.payload);
    yield put(
      authActions.actions.uploadProfileImageSuccess(
        response.data.data.profileImage
      )
    );
    toast.success("Profile image updated successfully");
  } catch (error) {
    yield put(authActions.actions.uploadProfileImageFailure(error.message));
    toast.error("Failed to update profile image");
  } finally {
    yield put(authActions.actions.resetProfileImageUploadStatus());
  }
}

function* updateUserSaga(action) {
  try {
    yield put(authActions.actions.updateUserRequest());
    const response = yield call(updateUserService, action.payload);
    yield put(authActions.actions.updateUserSuccess(response.data.data));
    toast.success("User details updated successfully");
  } catch (error) {
    yield put(authActions.actions.updateUserFailure(error.message));
    toast.error("Failed to update user details");
  } finally {
    yield put(authActions.actions.resetUserUpdateStatus());
  }
}

function* resetPasswordSaga(action) {
  try {
    const response = yield call(resetPasswordService, action.payload);
    if (response?.data?.message !== "Password Updated Successfully") {
      toast.error(response?.data?.message);
    } else {
      toast.success("Password reset successfully");
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error("Failed to reset password");
  }
}

function* deleteUserSaga(action) {
  try {
    yield put(authActions.actions.deleteUserRequest());
    yield call(deleteUserService, action.payload);
    yield put(authActions.actions.deleteUserSuccess());
    // yield put(authActions.actions.resetAuth());
    yield put(resetActions.actions.resetAll());

    toast.success("Account deleted successfully");
  } catch (error) {
    yield put(authActions.actions.deleteUserFailure(error.message));
    toast.error("Failed to delete account");
  }
}

export function* forgotPasswordSaga(action) {
  try {
    const data = yield forgotPasswordServ(action.payload);
    if (data) {
      yield put(authActions.actions.setCurrentUserSlice(data?.data));
      yield put(authActions.actions.setLoginTab(1));
      toast.success(data?.message);
    } else {
      toast.error("Failed to Authenticate");
    }
  } catch (err) {
    console.log("err", err);
    toast.error("Failed to Authenticate");
  }
}

function* changePasswordSaga(action) {
  try {
    const response = yield call(changePasswordService, action.payload);
    if (response?.data?.message !== "Password Updated Successfully") {
      toast.error(response?.data?.message);
    } else {
      toast.success("Password reset successfully");
      action.payload.navigate('/');
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error("Failed to reset password");
  }
}

function* updateTwoFASaga(action) {
  try {
    const response = yield call(update2FAService, action.payload);
    if (response.status !== 200) {
      toast.error(response?.message);
    } else {
      yield put(authActions.actions.setCurrentUserSlice(response?.data?.updatedUser));
      toast.success("2FA updated for all users");
    }
  } catch (error) {
    console.error("Error:", error);
    toast.error("Failed to update");
  }
}

function* verifyTwoFaSaga(action) {
  try {
    const response = yield call(verify2FAotpService, action.paylaod);
    if (response.status === 200) {
      toast.success("OTP verified");
      yield put(authActions.actions.setCurrentUserSlice(response?.data?.updatedUser))
    } else {
      toast.error("OTP not valid");
    }
  } catch (error) {
    console.error("Error verifying 2FA:", error);
  }
}

// eslint-disable-next-line require-yield
function* sendTwoFaOtpSaga(action) {
  try {
    toast.promise(
      send2FAotpService(action.paylaod),
      {
        loading: 'Sending OTP...',
        success: 'OTP sent to admin',
        error: 'OTP sending failed',
      }
    ).then((response) => {
      if (response?.status === 200) {
        // setIs2FAOpen(true);
      }
    }).catch((error) => {
      console.log(error);
    });
  } catch (error) {
    console.error("Error sending 2FA:", error);
  } finally {

  }
}

function* signUpSaga(action) {
  try {
    const user = yield call(createUserService, action.payload);
    console.log("user", user)
    if (user?.data) {
      yield put(authActions.actions.loginUserStatus(true));
      yield put(authActions.actions.setSignUpSuccessSlice(true));
      yield put(authActions.actions.setCurrentUserSlice(user?.data));
      yield put(authActions.actions.setUserRoleSlice(user?.data?.role));
      localStorage?.setItem("accessToken", user?.data?.accessToken);
      Cookies.set("refreshToken", user?.data?.refreshToken, {
        expires: 7,
        secure: true,
      });
    }
    toast.success("User created successfully");

  } catch (error) {
    toast.error(error?.response?.data?.message);
    console.log(error)
  }
}


function* validateTokenSaga() {
  try {

    const response = yield validateTokenService();

    if (response?.status === 200) {
      yield put(authActions.actions.loginUserStatus(true));
      yield put(authActions.actions.setSubscriptionStatus(SUBSCRIPTION_STATUS.active));
    }
    else if (response?.status === 401) {
      toast.error("Session expired. Please sign in again.");
      // yield put(authActions.actions.resetAuth());
      yield put(resetActions.actions.resetAll());
    }
    else if (response?.status === 402) {
      yield put(authActions.actions.setSubscriptionStatus(SUBSCRIPTION_STATUS.pending));
      toast.error("Access denied: Please purchase a subscription plan to continue.");
    }
    else if (response?.status === 403) {
      toast.error("Your subscription has expired. Please renew your plan to regain access.");
      yield put(authActions.actions.setSubscriptionStatus(SUBSCRIPTION_STATUS.expired));
    }

  } catch (error) {
    console.log(error)
  }
}

export function* watchAuthAsync() {
  yield takeEvery(USER_LOGIN, loginSaga);
  yield takeEvery(USER_LOGOUT, logoutSaga);
  yield takeEvery(USER_VERIFY, verifyUserSaga);
  yield takeEvery(UPLOAD_PROFILE_IMAGE, uploadProfileImageSaga);
  yield takeEvery(UPDATE_USER, updateUserSaga);
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
  yield takeEvery(DELETE_USER, deleteUserSaga);
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordSaga)
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga)
  yield takeEvery(TWO_FA, updateTwoFASaga)
  yield takeEvery(VERIFY_TWO_FA_OTP, verifyTwoFaSaga)
  yield takeEvery(SEND_TWO_FA_OTP, sendTwoFaOtpSaga)
  yield takeEvery(SIGN_UP, signUpSaga)
  yield takeEvery(VALIDATE_ACCESS_TOKEN, validateTokenSaga)
}
